$(document).ready(function() {
    $('#head a.mobileNav').click(function(){
        if($('#head .navs').hasClass('active')){
            /*
            $('a.mobileNav').removeClass('active');
            $('a.mobileNav').find('i').addClass('icon-menu').removeClass('icon-close');
            $('.dropdown').removeClass('showed');
            $('a.mobileNav').find('span').html($('a.mobileNav').attr('data-menu'));
            setTimeout(
                function(){
                    $('.dropdown').removeClass('active');
                }, 100);
            setTimeout(
                function(){
                    $('#head').removeClass('open');
            }, 800);
            */
            $('#head .navs').removeClass('active');
            $('a.mobileNav').find('i').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('#head .navs').addClass('active');
            $('a.mobileNav').find('i').removeClass('icon-menu').addClass('icon-close');
            /*
            $('#head').addClass('open');
            $('.dropdown').addClass('active');
            $('a.mobileNav').addClass('active');
            $('a.mobileNav').find('i').removeClass('icon-menu').addClass('icon-close');
            $('a.mobileNav').find('span').html($('a.mobileNav').attr('data-close'));
            setTimeout(
                function(){
                    $('.dropdown').addClass('showed');
                }, 100);
            */
        }
    });

    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    var owlProduct = $('.owl-photogalery');
    owlProduct.owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        dots: true,
        navText: ['<i class="icon-chevron"><i>','<i class="icon-chevron"><i>'],
        items: 4,
        slideBy: 3,
        autoplay:false,
        stagePadding:15,
        responsive : {
            0 : {
                items: 1
            },
            504 : {
                items: 2
            },
            880 : {
                items: 3
            },
            1100 : {
                items: 4
            }
        }
    });
});
